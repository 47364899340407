<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loadingState">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                              <b-col>
                                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="pestData.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :disabled="pestData.org_id != ''"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <ValidationProvider name="Pest Name (En)" vid="name_id" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="name_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.pest_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name_id"
                                    v-model="pestData.name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <ValidationProvider name="Pest Name (Bn)" vid="name_bn_id" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="name_bn_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.pest_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="name_bn_id"
                                    v-model="pestData.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <ValidationProvider name="Scientific Name" vid="sci_name_id" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="sci_name_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.pest_sci_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    style="font-style:italic"
                                    id="sci_name_id"
                                    v-model="pestData.scientific_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <ValidationProvider name="Description (En)" vid="description_id" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="description_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.pest_des_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                    id="description_id"
                                    v-model="pestData.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <ValidationProvider name="Description (Bn)" vid="decription_bn_id" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="decription_bn_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('vm_museum_management.pest_des_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                    id="decription_bn_id"
                                    v-model="pestData.description_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col text-right">
                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                  &nbsp;
                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { pestInfoStoreApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.pestData.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getPestInfoData()
      this.pestData = tmp
      this.pestData.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        pestData: {
            org_id: 0,
            name: '',
            name_bn: '',
            scientific_name: '',
            description: '',
            description_bn: '',
            edit_id: ''
        }
    }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      }
  },
  methods: {
    getPestInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      result = await RestApi.postData(virtualMuseumServiceBaseUrl, pestInfoStoreApi, this.pestData)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.loadingState = false
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-3')
      } else {
            this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
