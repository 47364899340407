<template>
      <!-- iq-card -->
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <iq-card>
          <template v-slot:body>
          <b-row class="details">
            <!-- beneficiary -->
            <b-table-simple striped bordered small hover>
            <tbody>
              <b-tr>
                <b-th colspan="1">{{ $t('globalTrans.organization') }} :</b-th>
                <b-td colspan="5">{{ getOrgName(pestData.org_id) }}</b-td>
              </b-tr>

              <b-tr>
                <b-th colspan="1">{{ $t('vm_museum_management.pest_name_en') }} :</b-th>
                <b-td colspan="5">
                    <span v-if="$i18n.locale === 'en'">{{ pestData.name }}</span>
                    <span v-if="$i18n.locale === 'bn'">{{ pestData.name_bn }}</span>
                </b-td>
              </b-tr>

              <b-tr>
                <b-th colspan="1">{{ $t('vm_museum_management.pest_sci_name') }} :</b-th>
                <b-td colspan="5" style="font-style:italic">{{ pestData.scientific_name }}</b-td>
              </b-tr>

              <b-tr>
                <b-th colspan="1">{{ $t('vm_museum_management.pest_des') }} :</b-th>
                <b-td colspan="5">
                    <span v-if="$i18n.locale === 'en'">{{ pestData.description }}</span>
                    <span v-if="$i18n.locale === 'bn'">{{ pestData.description_bn }}</span>
                </b-td>
              </b-tr>
            </tbody>
           </b-table-simple>
         </b-row>
          <b-col class="text-right">
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
         </b-col>
          </template>
        </iq-card>
      </b-col>
</template>

<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['id'],
  components: {
  },
  created () {
    if (this.id) {
      const tmp = this.getPestInfoData()
      this.pestData = tmp
      this.pestData.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        pestData: {
            org_id: 0,
            name: '',
            name_bn: '',
            scientific_name: '',
            description: '',
            description_bn: '',
            edit_id: ''
        }
    }
  },
  methods: {
    getPestInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return tmpData === undefined ? {} : JSON.parse(JSON.stringify(tmpData))
    },
    getOrgName (id) {
          const objectData = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
              if (this.$i18n.locale === 'bn') {
                  return objectData.text_bn
              } else {
                  return objectData.text_en
              }
      }
  }
}
</script>
